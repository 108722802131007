<template>
    <div class="page-body clone-dashboard">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Create Clone Dashboard</span>
            </div>
            <div class="my-4 mx-4">
                <Input label="Dashboard Name" name="Dashboard Name" id="dashboardNameInput"
                    placeholder="Enter dashboard name" v-model="dashboardName" inputClass="input-add-new-dashboard mt-3"
                    labelClass="class-label" :input_style="'text-transform:capitalize'"/>
            </div>
            <div class="d-flex justify-content-center" style="bottom: 10%;">
                <button type="button" class="primary btn-save" @click="$emit('cloneDashboard', dashboardName)">Save
                    Dashboard</button>
                <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';

export default {
    name: 'CloneDashboardModal',
    components: { NoButtonModal },
    data() {
        return {
            dashboardName: '',
        }
    },
    mounted() {
        this.$nextTick(() => {
            document.getElementById('dashboardNameInput').focus();
        });
    },
};
</script>

<style scoped>
.modal-content-header {
    background-color: #f1f7ff;
    padding: 10px;
    display: flex;
    align-items: center;
}

.btn-save {
    border-radius: 10px;
    background: #5155C3 !important;
    box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
}

.text-link:hover {
    background: #5155C3;
}

button.no-bg:hover {
    background: none;
}

.btn-cancel,
.btn-cancel:hover {
    border-radius: 10px;
    border: 2px solid #AC2734;
}
</style>

<style>
.clone-dashboard .modal__content {
    padding: 0;
    border-radius: 10px;
    min-height: fit-content;
}

.clone-dashboard .class-label {
    color: #666C6D;
    font-size: 1rem;
    font-weight: 600;
}
</style>